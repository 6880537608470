import {useState} from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Container,
  ContentLayout,
  Header,
  Input,
  Link,
  SpaceBetween
} from "@cloudscape-design/components-themed";
import {Navigation} from "../components/Navigation";
import {TopNav} from "../components/TopNavigation";

function Breadcrumbs() {
  const breadcrumbItems = [
    {
      text: 'Home',
      href: '/',
    },
  ];
  return <BreadcrumbGroup items={breadcrumbItems} expandAriaLabel="Show path" ariaLabel="Breadcrumbs"/>;
}

export default function App() {
  const [value, setValue] = useState("");

  return (
    <>
      <TopNav/>
      <AppLayout
        content={
          <ContentLayout header={
            <Header
              variant="h1"
              info={
                <Link variant="info" ariaLabel={'Information about Fire Kit Tools.'}/>
              }
              actions={<Button variant="primary">Get started</Button>}
            >
              Fire Kit Tools
            </Header>
          }>
            <SpaceBetween size="m">
              <Header variant="h1">Fire Kit Tools</Header>

              <Container>
                <SpaceBetween size="s">
                  <span>Start editing to see some magic happen</span>
                  <Input
                    value={value}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                  <Button variant="primary">Click me</Button>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </ContentLayout>
        }
        headerSelector="#header"
        breadcrumbs={<Breadcrumbs/>}
        navigation={<Navigation/>}
        // tools={toolsContent}
        // toolsOpen={toolsOpen}
        // onToolsChange={({ detail }) => setToolsOpen(detail.open)}
        // ariaLabels={appLayoutLabels}
        // notifications={<Notifications />}
      />
    </>
  );
}